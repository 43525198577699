import Cookies from 'universal-cookie'

import { isBrowser } from '@syconium/magnolia/src/lib/utils'

type RequestContext = {
  __TypeInfo: 'request'
  pageUrl: string
  pageTitle: string
  timezone: string
  userAgent: string
}

type UserContext = {
  __TypeInfo: 'user'
  emailAddress: string
}

type ThirdPartyContext = {
  __TypeInfo: 'thirdparty'
  heapSessionId: string
}

type MarketingContext = {
  __TypeInfo: 'marketing'
  source?: string
  medium?: string
  campaign?: string
  ttCampaignId?: string
}

type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue }

type EventToTrack = {
  event: string
  attributes: Record<string, JSONValue>
  user?: Omit<UserContext, '__TypeInfo'>
}

export class SignalClient {
  track({ event, attributes, user }: EventToTrack) {
    if (process.env.NEXT_PUBLIC_USER_SIGNALS_ENABLED !== 'true') return

    const data = {
      event: event,
      attributes: attributes,
      context: this.createContext({ user }),
      createdAt: new Date().toISOString(),
    }

    const url = process.env.NEXT_PUBLIC_REST_API_BASE_URL + '/user-signal/v1/signals'

    fetch(url, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(data),
    }).catch(error => console.error(error))
  }

  private createContext({ user }: { user?: Omit<UserContext, '__TypeInfo'> }) {
    const context = []

    if (user) {
      context.push(this.buildUserContext({ user }))
    }

    if (isBrowser()) {
      context.push(this.buildRequestContext())

      const marketingContext = this.buildMarketingContext()
      if (marketingContext) {
        context.push(this.buildMarketingContext())
      }

      const thirdpartyContext = this.buildThirdPartyContext()
      if (thirdpartyContext) {
        context.push(thirdpartyContext)
      }
    }

    return context
  }

  private buildRequestContext() {
    const requestContext: RequestContext = {
      __TypeInfo: 'request',
      pageTitle: document.title,
      pageUrl: document.URL,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
      userAgent: window.navigator.userAgent,
    }
    return requestContext
  }

  private buildUserContext({ user }: { user: Omit<UserContext, '__TypeInfo'> }) {
    const userContext: UserContext = {
      __TypeInfo: 'user',
      emailAddress: user.emailAddress,
    }
    return userContext
  }

  private buildThirdPartyContext() {
    if (globalThis.heap) {
      const heapCookie = this.lookupHeapCookie()

      if (heapCookie) {
        const thirdpartyContext: ThirdPartyContext = {
          __TypeInfo: 'thirdparty',
          heapSessionId: heapCookie.sessionId,
        }
        return thirdpartyContext
      }
    }
    return null
  }

  private buildMarketingContext() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('utm_campaign')) {
      const marketingContext: MarketingContext = {
        __TypeInfo: 'marketing',
        source: urlParams.get('utm_source') || '',
        medium: urlParams.get('utm_medium') || '',
        campaign: urlParams.get('utm_campaign') || '',
        ttCampaignId: urlParams.get('tt_campaignid') || '',
      }
      sessionStorage.setItem('figsMarketingContext', JSON.stringify(marketingContext))
      return marketingContext
    }

    const marketingContextString = sessionStorage.getItem('figsMarketingContext')
    if (marketingContextString) {
      const marketingContext = JSON.parse(marketingContextString)
      return marketingContext
    }

    return null
  }

  private lookupHeapCookie() {
    const heapCookieId = '_hp2_id.' + process.env.NEXT_PUBLIC_HEAP_APP_ID

    return new Cookies().get(heapCookieId)
  }
}
